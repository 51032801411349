<template>
    <div class="wrapper">
        <div id="dialogue">
            <div class="InforList">
                <div class="search ng-pristine ng-valid">
                    <el-input
                        class="search_inp"
                        type="text"
                        placeholder="请输入搜索内容"
                        prefix-icon="el-icon-search"
                        v-model="keyword"
                        @input="searchFun"
                    >
                    </el-input>
                    <span class="add_icon" @click="dropdownClick('1')">
                        <span class="iconfont iconzhankai"></span>
                    </span>
                </div>
                <nav>
                    <a
                        class="infor"
                        :class="{active:tabIndex==0}"
                        @click="changeTab(0)"
                    >
                        <i class="iconfont iconhuihua"></i>
                        <span v-if="tabIndex==0" class="dot iconfont icondot"></span>
                        <span v-if="singleChat > 0" class="final_reading">{{ singleChat }}</span>
                    </a>
                    <a
                        class="staff"
                        :class="{active:tabIndex==1}"
                        @click="changeTab(1)"
                    >
                        <i class="iconfont iconkehuqunzu"></i>
                        <span v-if="tabIndex==1" class="dot iconfont icondot"></span>
                        <span v-if="groupChat > 0" class="final_reading">{{ groupChat }}</span>
                    </a>
                    <a class="Launch" @click="changeTab(2)" :class="{active:tabIndex==2}">
                        <i class="iconfont iconlianxirenbeifen"></i>
                        <span v-if="tabIndex==2" class="dot iconfont icondot"></span>
                    </a>
                </nav>
                <div class="List">
                    <div
                        id="infor"
                        :class="{active:tabIndex==0}"
                    >
                        <!-- 最近会话 -->
                        <session
                            @clickContact="enterChat"
                            :sessionlist-data="sessionlistData"
                            :tab-index="tabIndex"
                            ref="session"
                        ></session>
                    </div>
                    <div
                        id="staff"
                        :class="{active:tabIndex==1}"
                    >
                        <!-- 群 -->
                        <groupChat
                            @clickContact="enterChat"
                            :contacts-list="contactsListDataGroup"
                            :tab-index="tabIndex"
                            ref="groupChat"
                        ></groupChat>
                    </div>
                    <div
                        id="staff"
                        :class="{active:tabIndex==2}"
                    >
                        <!-- 联系人 -->
                        <contacts
                            @clickContact="enterChat"
                            :contacts-list="contactsListDataAll"
                            :tab-index="tabIndex"
                            ref="contacts"
                        ></contacts>
                    </div>
                    <!-- <div id="Launch"></div> -->
                </div>
            </div>
            <!-- 聊天窗口 -->
            <chat
                :is-show="show"
                :session="sessionData"
                :chatdata="chatData"
                :contacts-data="contactsListDataAllFilter"
                :tab-index="tabIndex"
                :session-type="sessionType"
                @nameChangeFun="nameChangeFun"
                @clickContact="enterChat"
                ref="chat"
            ></chat>
            <div
                v-if="!show"
                class="chat-default"
            ></div>
        </div>
        <choseMembers
            :show-dialogue="showDialogue"
            :dialogue-tit="'选取联系人'"
            @update-show="updateShowDialogue"
            @sure-members="sureMembers"
        ></choseMembers>
        <teamChoseMembers
            :show-dialogue="showTeamChoseDialogue"
            :dialogue-tit="'创建群组'"
            @update-show="updateShowDialogue"
            :type="'createTeam'"
            @sure-members="sureMembers"
        ></teamChoseMembers>
    </div>
</template>

<script>
import session from './components/dialogue/Session';
import contacts from './components/dialogue/Contacts';
import groupChat from './components/dialogue/groupChat';
import chat from './components/dialogue/Chat';
import choseMembers from './components/choseMembers';
import teamChoseMembers from './components/teamChoseMembers';
import util from '@/utils/util';
export default {
    components: {
        session,
        contacts,
        groupChat,
        chat,
        choseMembers,
        teamChoseMembers,
    },
    data() {
        return {
            show: false,
            sessionData: '',
            chatData: {},
            tabIndex: 0,
            tabIndexFlag: false,
            showDialogue: false, // 显示创建群组弹框
            data: [],
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            type: '',
            contactsListDataGroup: [],
            contactsListDataAll: [],
            sessionlistData: [],
            dialogueTit: '',
            showTeamChoseDialogue: false,
            keyword: '',
            sessionType: '',

            deptId: '',

            singleChat: 0,
            groupChat: 0,
        };
    },
    methods: {
        async nameChangeFun() {
            await this.getFriendsAll();
        },
        enterChat(session, rowData, obj) {
            if (session) {
                this.show = true;
                this.sessionData = session;
                this.sessionType = rowData.type || '';
                this.chatData = rowData;
            } else {
                // this.getFriendsAll();
                if (rowData) {
                    this.show = false;
                    this.sessionData = '';
                    this.sessionType = '';
                    this.chatData = {};
                }
                this.getFriends();
            }
            if (obj && obj.refName) {
                this.$refs[obj.refName].activeIndex = null;
            }
            if (obj && obj.flag) {
                this.tabIndex = 0;
                this.$refs.session.activeIndex = session;
            }
        },
        changeMenu(item, index) {
            this.selectedSlide = index;
        },
        async changeTab(tabIndex) {
            // this.getget();
            this.$refs.chat.scrollSign = true;
            this.$refs.chat.sendFlag = true;
            this.$refs.chat.messageId = true;
            if (this.tabIndex === tabIndex) {
                if (!this.tabIndexFlag) {
                    this.tabIndexFlag = true;
                    this.tabIndex = tabIndex;
                    this.show = false;
                    this.contactsListDataAll = [];
                    await this.getFriendsAll();
                    setTimeout(() => {
                        this.tabIndexFlag = false;
                    }, 1000);
                }
            } else {
                this.tabIndex = tabIndex;
                this.show = false;
                this.contactsListDataAll = [];
                await this.getFriendsAll();
            }
        },
        // getget() {
        //     this.$axios.get('/interfaceApi/message/MessageInfo/get_noread_count').then(res => {
        //         console.log(res, 'resss');
        //         // this.$message.success(res);
        //     }).catch(error => {
        //         this.$message.error(error.message);
        //     });
        // },
        updateShowDialogue(val) {
            this.showDialogue = val;
            this.showTeamChoseDialogue = val;
        },
        // 选取人员完成后回调
        sureMembers(data) {
            const that = this;
            if (data.data.length < 1) {
                this.$message.warning('未选择成员');
                return;
            }
            const users = [];
            data.data.forEach(item => {
                users.push(item.User_Id);
            });
            if (data.type === 'addMembers') {
                // // 通过分页的方式获取公开群组的列表。
                // // 每页期望返回的群组数。
                // const pageSize = 10;
                // // 获取数据的起始位置。首次调用可为空，后续由服务器返回。
                // const cursor = '';
                // this.$WebIM.conn.fetchPublicGroupsFromServer(pageSize, cursor)
                //     .then(() => {
                //         console.log('get group list success.');
                //     })
                //     .catch(reason => {
                //         console.log('get group list fail.', reason);
                //     });


                // this.getFriendsAll(rows);
            } else if (data.type === 'createTeam') {
                // const option = {
                //     groupname: '群',
                //     desc: 'A description of a group',
                //     members: users,
                //     public: true,
                //     approval: true,
                //     allowinvites: true,
                //     inviteNeedConfirm: false,
                //     // owner: this.deptId, // 群主
                //     owner: 'SU2303000005', // 群主
                // };

                // const option = {
                //     groupname: '群',
                //     description: 'A description of a group',
                //     members: users,
                //     allowinvites: true,
                //     // owner: this.deptId, // 群主
                //     owner: 'SU2303000005', // 群主
                // };

                // this.$axios.post('/interfaceApi/message/MessageNetease/create_group', option).then(res => {
                //     console.log(res, 'resss');
                //     this.getFriendsAll();
                //     this.$message.success('创建成功');
                // }).catch(error => {
                //     this.$message.error(error.message);
                // });

                // 环信api
                const option = {
                    data: {
                        groupname: '群组',
                        desc: '',
                        // members: ['SU2303000005'],
                        members: users,
                        public: true,
                        approval: false,
                        allowinvites: true,
                        inviteNeedConfirm: false,
                        maxusers: 500,
                        ext: '',
                    },
                };
                this.$WebIM.conn.createGroup(option).then(() => {
                    that.getFriendsAll();
                });
            }
            this.updateShowDialogue(false);
        },
        // 下拉 添加联系人、创建群组
        dropdownClick(command) {
            if (command === 'addMembers') {
                this.showDialogue = true;
            } else {
                this.showTeamChoseDialogue = true;
            }
        },
        // 添加联系人接口
        betchAddFriend(data) {
            this.$axios
                .post('/interfaceApi/message/MessageNetease/betch_add_user_friend', data)
                .then(res => {
                    this.$message.success(res);
                    this.getFriends();
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 获取所有联系人
        async getFriendsAll() {
            this.contactsListDataAllFilter = [];
            this.$WebIM.conn.getJoinedGroups({
                pageNum: 0,
                pageSize: 20,
                needAffiliations: true,
                needRole: true,
            }).then(res => {
                res.entities.forEach(item => {
                    this.contactsListDataAllFilter.push({
                        name: item.groupName,
                        User_Name: item.groupName,
                        User_Id: item.groupId,
                        id: item.groupId,
                        AvatarUrl: '',
                        // friend_id: '',
                        friend_acc_id: 'p2p-' + item.groupId,
                        type: 'groupChat',
                    });
                });
            });
            const res = await this.getUserFun();
            if (res) {
                const newArr = res.map(item => {
                    item.friend_acc_id = item.Contacts_AccId;
                    item.id = item.Contacts_AccId;
                    item.name = item.User_Name;
                    return item;
                });
                this.contactsListDataAllFilter.push(...newArr);
            }
            this.getFriends();
        },
        getUserFun() {
            return this.$axios.get(`/interfaceApi/message/MessageNetease/get_friends?search=${this.keyword}`);
        },
        // 获取常用联系人
        getFriends() {
            const chatData = [];
            const groupChatData = [];
            this.$WebIM.conn.getServerConversations({ pageSize: 50, cursor: '' }).then(res => {
                let resData = res.data.conversations;
                resData.forEach((item, i) => {
                    if (item.isPinned) {
                        item.sort = i + 1;
                    } else {
                        item.sort = resData.length + i;
                    }
                });
                resData = resData.sort((x, y) => { return x.sort - y.sort; });
                resData.forEach(item => {
                    let timeStr = '';
                    const date1 = util.timeFormat(new Date(item.lastMessage.time), 'yyyy-MM-dd HH:mm:ss');
                    const date2 = util.timeFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
                    const time1 = new Date(date2).getTime() - new Date(`${date2.split(' ')[0]} 00:00:00`).getTime();
                    const time2 = new Date(date2).getTime() - new Date(date1).getTime();
                    const time = date1.split(' ')[1].split(':');
                    if (time1 >= time2) {
                        timeStr = `${time[0]}:${time[1]}`;
                    } else if (time1 + 1000 * 60 * 60 * 24 > time2) {
                        timeStr = `昨天${time[0]}:${time[1]}`;
                    } else {
                        timeStr = date1.split(' ')[0];
                    }
                    // new Date(`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`);
                    let name = '';
                    let User_Gender = 1;
                    this.contactsListDataAllFilter.forEach(dat => {
                        if (item.conversationId === dat.User_Id || item.conversationId === dat.Contacts_AccId) {
                            name = dat.name;
                            User_Gender = dat.User_Gender;
                        }
                    });
                    const str = item.lastMessage.ext.type === 'file' ? '【文件消息】' + item.lastMessage.ext.name : '【语音消息】';
                    if (item.conversationType === 'singleChat') {
                        this.singleChat += item.unReadCount;
                        chatData.push({
                            name: name ? name : item.conversationId,
                            id: item.conversationId,
                            unread: '',
                            updateTimeShow: timeStr,
                            lastMsgShow: item.lastMessage.type === 'custom' ? str : item.lastMessage.msg,
                            type: item.conversationType,
                            User_Gender,
                            unReadCount: item.unReadCount,
                            isPinned: item.isPinned,
                        });
                    } else if (item.conversationType === 'groupChat') {
                        this.groupChat += item.unReadCount;
                        groupChatData.push({
                            name: name ? name : item.conversationId,
                            id: item.conversationId,
                            unread: '',
                            updateTimeShow: timeStr,
                            lastMsgShow: item.lastMessage.type === 'custom' ? str : item.lastMessage.msg,
                            type: item.conversationType,
                            User_Gender,
                            unReadCount: item.unReadCount,
                            isPinned: item.isPinned,
                        });
                    }
                });
                this.sessionlistData = this.keyword ? chatData.filter(item => {
                    return item.name.indexOf(this.keyword) !== -1;
                }) : chatData;
                this.contactsListDataGroup = this.keyword ? groupChatData.filter(item => {
                    return item.name.indexOf(this.keyword) !== -1;
                }) : groupChatData;
                this.contactsListDataAll = this.keyword ? this.contactsListDataAllFilter.filter(item => {
                    return item.name.indexOf(this.keyword) !== -1;
                }) : this.contactsListDataAllFilter;
            });
        },
        // 获取群
        getFriendsGroup() {
            this.$axios
                .get(`/interfaceApi/message/MessageNetease/all_contacts?deptId=${this.deptId}&search=${this.keyword}`)
                .then(res => {
                    const newArr = res.map(item => {
                        item.friend_acc_id = 'p2p-' + item.Contacts_AccId;
                        item.time = '16:00';
                        return item;
                    });
                    this.contactsListDataGroup = newArr;
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 查询
        async searchFun() {
            await this.getFriendsAll();
        },
    },
    async created() {
        this.deptId = this.$takeTokenParameters('DeptId');
        await this.getFriendsAll();
    },
};
</script>
<style lang="stylus" scoped>
.wrapper
    width 100%
    height 100%
.cdialog
    .el-dialog__body
        height calc(100% - 54px)
.search_inp
    width calc(100% - .6rem)
.search
    display flex
.add_icon
    width .5rem
    height 100%
    display flex
    justify-content center
    align-items center
    color #C2C2C2
    cursor pointer
.InforList
    nav
        height .58rem !important
        a
            height .58rem !important
            display flex
            flex-direction column
            position relative
            .dot
                position absolute
                bottom -.02rem
                left calc(50% - .08rem)
                color #3C9CFF
                font-size .16rem
            i
                color #A2C8F2
            .final_reading
                color #fff
                position absolute
                z-index 1
                display flex
                justify-content center
                align-items center
                right .35rem
                top .05rem
                min-width .32rem
                height .22rem
                font-size .16rem
                background #F64A2C
                border-radius 8px
                border 1px solid #FFFFFF
            &.active
                i
                    color #3C9CFF
        i
            font-size .26rem

/* .el-dialog__body>div{
    height: 100%;
} */
</style>

